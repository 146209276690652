<template >
    <NavComponent></NavComponent>
    <RouterView ></RouterView>
</template>

<script>
//import HomeComponent from './components/HomeComponent.vue'
// import LoginComponent from './components/LoginComponent.vue'
// import RegisterComponent from './components/RegisterComponent.vue'
import NavComponent from './components/NavComponent.vue'
import './styles.css'
export default {
  name: 'App',
  components: {
    NavComponent,
    //HomeComponent
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

#app {
  font-family: 'Montserrat',Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html, body, #app {
  height: 100%;
  margin: 0;
}
</style>
