<template>
<div>
    <div class=" mx-2 mx-md-3 mx-lg-4 p-4 w-auto w-md-auto h-auto  rounded-lg fade-in text-start" v-if="!loading" >
        <!-- Modal -->
        <div class="modal fade" id="registroModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"  >
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Registrar Usuario</h5>
                    <button ref="closeButton" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="actualizarListFecha()"></button>
                </div>
                <div class="modal-body">
                    <RegisterComponent @registrado="cerrarModal"></RegisterComponent>
                </div>
                </div>
            </div>
        </div>
        <!-- Modal UPDATE-->
        <div class="modal fade" id="updateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"  >
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Actualizar Usuario</h5>
                    <button ref="closeButton2" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="actualizarListFecha()"></button>
                </div>
                <div class="modal-body">
                    <UpdateUsersComponent @actualizado="cerrarModal2" v-if="authStore.userEdit"></UpdateUsersComponent>
                </div>
                </div>
            </div>
        </div>
        <div class="text-start mb-2" v-if="authStore.user && authStore.user.rol>=3">Empresa:</div>
        <div class="text-start mb-2" v-else ></div>
        <div class="d-flex flex-wrap flex-lg-nowrap justify-content-start gap-3">
            
            <!-- Empresa search bar -->   
            <div class=" w-50 text-start" style="max-width:20em;" v-if="authStore.user && authStore.user.rol>=3 ">
                        <input
                        type="text"
                        class="form-control text-start"
                        placeholder="Empresa"
                        v-model="razon_social"
                        @input="flag=true"
                        />
                <div class="row mt-2" v-if="empresasFiltradas.length && razon_social!='' && flag">
                <div class="col-md-12 " >
                    <ul class=" w-auto list-group position-absolute overflow-scroll "  style="z-index: 50; max-height:20rem;">
                    <a
                        class="list-group-item"
                        v-for="empresa in empresasFiltradas"
                        :key="empresa.id"
                        @click="razon_social=empresa.razon_social;flag=false"
                        href="#"
                    >
                        {{ empresa.razon_social }}
                    </a>
                    </ul>
                </div>
                </div>
            </div>
            
            <!-- FORMS FILTROS -->
            <div class="input-group w-auto">
                <span class="input-group-text"  id="basic-addon1">Nombre</span>
                <input type="text" class="form-control" v-model="nombre" placeholder="Ingrese una Nombre ">
            </div>

            <div class="input-group w-auto">
                <span class="input-group-text" id="basic-addon1">Email</span>
                <input type="text" class="form-control"  v-model="email" placeholder="Ingrese una Email">
            </div>
            <div class="d-flex flex-nowrap ms-auto" role="group">
                <!-- Button trigger modal -->
                <button type="button" class="btn btn-success ms-auto me-3 " style="white-space: nowrap;" data-bs-toggle="modal" data-bs-target="#registroModal">
                    Registrar Usuario
                </button>
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-bs-toggle="dropdown">
                    {{selectedSize}}
                </button>
                <div class="dropdown-menu" style="width: auto;min-width: 0;" >
                    <a  @click="selectedSize= reg" v-for="reg in cantReg" :key="reg" class="dropdown-item" href="#">{{ reg }}</a>
                    </div>
            </div>
            
        </div>
    
        <nav class="mt-3 d-flex justify-content-center" >
            <ul class="pagination " v-show= "!loading">
                <li class="page-item">
                <a href='#' class="page-link" @click="previousPage" v-show= "usuarios.length!=0" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-for="pag in totalPages" :key="pag" :class="{ active: pag === page }"><a class="page-link" @click="page=pag" href="#">{{pag}}</a></li>
                <li class="page-item">
                <a href='#' class="page-link" @click="nextPage" v-show= "usuarios.length!=0" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
        <!-- TABLA -->
        <div class="table-responsive">
            <div class="fw-italic fw-light mb-1">Registros: {{usuariosFiltrados.length}}</div>
            <table class="table table-bordered table-hover  overflow-x:auto">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Apellido</th>
                <th scope="col">Email</th>
                <th v-if="authStore.user && authStore.user.rol >= 3" scope="col">IDJC</th>
                <th scope="col">Rol</th>
                <th scope="col">Logs</th>
                <th scope="col">Empresa</th>
                <th scope="col">Fecha Creación</th>
                <th scope="col">Gestionar</th>
                </tr>
            </thead>
            <tbody v-if="!loading">
                <tr v-for="(usuario, index) in usuariosPaginados" :key="index" >
                <th scope="row">{{ index + 1 + bottom}}</th>
                <td>{{ usuario.nombre }}</td>
                <td>{{ usuario.apellido }}</td>
                <td>{{ usuario.email }}</td>
                <td v-if="authStore.user && authStore.user.rol >= 3">{{ (u_usuarios.find(element => element.id==usuario.idjc))?.usuario }}</td>
                <td>{{ usuario.rol==1?'Usuario':usuario.rol==2?'Administrador':usuario.rol==3?'Proveedor':usuario.rol==4?'SuperUsuario':'' }}</td>
                <td>
                    <button class= "btn btn-primary btn-sm " @click="irLogs(usuario)">Ver logs</button>
                </td>
                <td :class="{ 'text-truncate': !isHovered }"
                    @mouseenter="isHovered = true"
                    @mouseleave="isHovered = false"
                    style="max-width: 11rem; overflow-wrap: break-word;">{{ usuario.empresa.razon_social }}
                </td>
                <td>{{ (new Date(usuario.created_at)).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' }) }}</td>
                <td class="d-flex flex-nowrap"> 
                    <button @click="authStore.setUser(usuario)" class="btn btn-outline-warning btn-sm me-1" data-bs-toggle="modal" data-bs-target="#updateModal"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M11.013 1.427a1.75 1.75 0 0 1 2.474 0l1.086 1.086a1.75 1.75 0 0 1 0 2.474l-8.61 8.61c-.21.21-.47.364-.756.445l-3.251.93a.75.75 0 0 1-.927-.928l.929-3.25c.081-.286.235-.547.445-.758l8.61-8.61Zm.176 4.823L9.75 4.81l-6.286 6.287a.253.253 0 0 0-.064.108l-.558 1.953 1.953-.558a.253.253 0 0 0 .108-.064Zm1.238-3.763a.25.25 0 0 0-.354 0L10.811 3.75l1.439 1.44 1.263-1.263a.25.25 0 0 0 0-.354Z"></path></svg></button>
                    <button @click="async()=>{await authStore.handleUserDelete(usuario.id);actualizarListFecha()}" class="btn btn-outline-danger btn-sm ms-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12"><path d="M2.22 2.22a.749.749 0 0 1 1.06 0L6 4.939 8.72 2.22a.749.749 0 1 1 1.06 1.06L7.061 6 9.78 8.72a.749.749 0 1 1-1.06 1.06L6 7.061 3.28 9.78a.749.749 0 1 1-1.06-1.06L4.939 6 2.22 3.28a.749.749 0 0 1 0-1.06Z"></path></svg></button>

                </td>
                </tr>
            </tbody>
            
            </table>
        </div>
        <!-- MENSAJE VACIO -->
        
        <h3 v-if="(usuariosPaginados.length==0) && !loading" class= " display-7 text-muted bg-light text-center">
        Aun no se tienen registros
        </h3>
        <nav  class="mt-3 d-flex justify-content-center" >
            <ul class="pagination " v-if= "loading">
                <li class="page-item">
                <a href='#' class="page-link" @click="previousPage" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                </a>
                </li>
                <li class="page-item" v-for="pag in totalPages" :key="pag" :class="{ active: pag === page }"><a class="page-link" @click="page=pag" href="#">{{pag}}</a></li>
                <li class="page-item">
                <a href='#' class="page-link" @click="nextPage" >
                    <span aria-hidden="true">&raquo;</span>
                </a>
                </li>
            </ul>
        </nav>
    </div>
    <div v-if="loading" class="spinner-border m-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        </div>
</template>

<script >
import {ref,watch,onMounted,computed} from 'vue'
import axios from 'axios'
import {useAuthStore} from '../stores/auth'
import router from '../router/index';
import RegisterComponent from '../components/RegisterComponent.vue'
import UpdateUsersComponent from '../components/UpdateUsersComponent.vue'
export default {
    
  components: { RegisterComponent,UpdateUsersComponent },
    setup(){
        const flag=false;
        const loading=ref(false);
        const loading2=ref(false);
        let totalPages=0
        let selectedSize=ref(25);
        let page=ref(1);
        let bottom=ref(0);
        const cantReg=[25,50,75,100,200];
        let top=ref(cantReg[0]);

        const usuarios = ref([]);
        const u_usuarios=ref([])
        const nombre=ref('');
        const email=ref('');
        const razon_social=ref('');
        const empresas=ref([]);
        const empresaSeleccionada=ref('');
        
        watch(page, () => {
            top.value=selectedSize.value*page.value
            bottom.value=top.value-selectedSize.value
        });
        watch(selectedSize,()=>{
            page.value=1
            bottom.value=0
            top.value=selectedSize.value
        })
        const actualizarListFecha = async () =>{
            loading.value=true;
            usuarios.value=[]
            try {
                let response;
                switch (authStore.user.rol) {
            case 4://superusuario
                response = await axios.get(`/api/getAllUsers`);
                break;
            case 3://proveedor
                response = await axios.get(`/api/getByProveedor/${authStore.user.id}`);
                break;
            case 2://admin
                response = await axios.get(`/api/getByIdjc/${authStore.user.idjc}`);
                break;
            default:
                response = await axios.get(`/api/getAllUsers`);
                break;
            }
                usuarios.value = response.data;
                totalPages.value=Math.ceil(usuarios.value.length / selectedSize.value);
                } 
            catch (error) {
                console.error('Error al obtener los datos:', error);
            }
            finally{
                loading.value=false;
            }
        }
        
        const actualizarListEmpresas= async()=>{
            loading2.value=true;
            try {
                if(authStore.user.rol==3){
                    const response = await axios.get('/api/getAllEmpresas');
                    empresas.value = response.data;
                    //razon_social.value=empresas.value[0].razon_social;
                }
                else{
                    empresas.value[0]=authStore.user.empresa;
                    razon_social.value=authStore.user.empresa.razon_social;
                }
            } catch (error) {
                console.error('Error fetching empresas:', error);
            }
            finally{
                loading2.value=false;
            }
        }
        onMounted(async () => {
            
            loading.value=true
            loading2.value=true
            const response1 = await axios.get("/api/getAllu_usuarios");
            u_usuarios.value = response1.data;
            if(authStore.razon_social){
                razon_social.value=authStore.razon_social;
                // empresaSeleccionada.value=authStore.empresaActual
            }
            try {
                await authStore.getUser();
                if(!authStore.user || authStore.user.rol==1){
                    router.push('/')
                }
                await actualizarListFecha();
                await actualizarListEmpresas();
                if(authStore.razon_social){
                    razon_social.value=authStore.razon_social;
                }
            } catch (error) {
                console.error("Error during onMounted execution:", error);
            }
        });
        //FILTRAR USUARIOS CON CAMPOS INGRESADOS
        const usuariosFiltrados = computed(() => {
            //return usuarios.value.filter(usuario => usuario.nombre.toLowerCase().includes(nombre.value.toLowerCase())).filter(usuario => usuario.email.toLowerCase().includes(email.value.toLowerCase())).filter(usuario=>usuario.empresa.razon_social.toLowerCase().includes(razon_social.value.toLowerCase()))
            return usuarios.value.filter(usuario => usuario.nombre.toLowerCase().includes(nombre.value.toLowerCase())).filter(usuario => usuario.email.toLowerCase().includes(email.value.toLowerCase())).filter(usuario=>usuario.empresa.razon_social.toLowerCase().includes(razon_social.value.toLowerCase()))
            
        });
        const usuariosPaginados = computed(() => {
            return usuariosFiltrados.value.slice(bottom.value, top.value);
        });
        //FILTRAR EMPRESAS CON CAMPO INGRESADO
        const empresasFiltradas=computed(()=>{
            //return empresas.value.filter(empresa=>empresa.razon_social.toLowerCase().includes(razon_social.value.toLowerCase()))
            return [];
        });
        totalPages = computed(() => Math.ceil(usuariosFiltrados.value.length / selectedSize.value));
        function previousPage (){
            if (page.value > 1) {
                page.value--;
                }
        }
        function nextPage (){
            if (page.value < totalPages.value) {
                page.value++;
            }
        }
        function irLogs(usuario){
            authStore.idjc=usuario.idjc;
            router.push('/Logs')
        }
        const authStore=useAuthStore();
        let isHovered=ref(false);
        const closeButton = ref(null);
        const cerrarModal = () => {
            if (closeButton.value) {
                closeButton.value.click(); // Simula el clic en el botón de cierre
            }
        };
        const closeButton2 = ref(null);
        const cerrarModal2 = () => {
            if (closeButton2.value) {
                closeButton2.value.click(); // Simula el clic en el botón de cierre
            }
        };
        return {
            cerrarModal2,
            closeButton2,
            closeButton,
            cerrarModal,
            irLogs,
            flag,
            previousPage,
            nextPage,
            selectedSize,
            top,
            actualizarListFecha,
            usuariosFiltrados,
            usuarios,
            empresas,
            cantReg,
            bottom,
            totalPages,
            usuariosPaginados,
            page,
            authStore,
            loading,
            loading2,
            nombre,
            email,
            razon_social,
            empresasFiltradas,
            empresaSeleccionada,
            RegisterComponent,
            isHovered,
            u_usuarios
            
        }
    }
}
</script>

<style>

</style>