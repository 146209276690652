import {createRouter, createWebHistory} from "vue-router";

import HomeComponent from '../components/HomeComponent.vue'
import RegisterComponent from '../components/RegisterComponent.vue'
import LoginComponent from '../components/LoginComponent.vue'
import LogsComponent from '../components/LogsComponent.vue'
import ForgotComponent from '../components/ForgotComponent.vue'
import ResetComponent from '../components/ResetComponent.vue'
import VerifyComponent from '../components/VerifyComponent.vue'
import UsersComponent from '../components/UsersComponent.vue'
import EmpresasComponent from '../components/EmpresasComponent.vue'
import RegisterEmpresaComponent from '../components/RegisterEmpresaComponent.vue'
const routes = [
    {
        path: '/', 
        name: 'Home',
        component: HomeComponent
    },
    {
        path: '/Login', 
        name: 'Login',
        component: LoginComponent},
    {
        path: '/Register', 
        name:'Register',
        component:RegisterComponent
    },
    {
        path: '/Logs', 
        name:'Logs',
        component:LogsComponent
    },
    {
        path: '/Users', 
        name:'Users',
        component:UsersComponent
    },
    {
        path: '/Empresas', 
        name:'Empresas',
        component:EmpresasComponent
    },
    {
        path: '/RegisterEmpresa', 
        name:'RegisterEmpresa',
        component:RegisterEmpresaComponent
    },
    {
        path: '/Forgot', 
        name:'Forgot',
        component:ForgotComponent
    },
    {
        path: '/password-reset/:token', 
        name:'password-reset',
        component:ResetComponent
    },
    {
        path: '/verify-email/:id/:hash', 
        name:'Verify',
        component:VerifyComponent
    },
    
    
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;