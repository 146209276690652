<template >
<div class="d-flex flex-column align-items-center justify-content-center h-75">

  <img class="mb-4" :src="require('@/assets/OPEN2KEYLOGO-01.png')" width="150" height="100">
  <!-- <div class="lead">Open2Key</div> -->
  <form @submit.prevent="login()" novalidate class="d-flex flex-column justify-content-center align-items-center" >
    <div class="mb-3">
      <input type="text" v-model="this.form.email" placeholder="Email" class="form-control form-control-l"/>
      <small><span v-for="error in v$.form.email.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
    </div>
    <div class="mb-3">
      <input type="password" v-model="form.password" placeholder="Password" class="form-control"/>
      <small><span v-for="error in v$.form.password.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
    </div>
    <div class="mb-3" >
      <button
        type="submit"
        class="btn btn-primary
        " :disabled="authStore.loginLoad"
      >
        Iniciar Sesión
      </button>
    </div>
  </form>
  <router-link
    to="/Forgot">
    Olvidaste tu contraseña?
  </router-link>
</div>
</template>

<script>
import { ref,computed} from "vue";
import { useAuthStore } from "../stores/auth";
import {useVuelidate} from '@vuelidate/core';
import { required$,email$} from './validations.js';


export default {
  setup(){
    const authStore = useAuthStore();
    const form = ref({
      email: "",
      password: "",
    });
    async function login(){
      
      await v$.value.form.$validate()
      if(!v$.value.form.$error){
        await authStore.handleLogin(form.value)
      }
    }
    const rules = computed (() => {
      return {
        form:{
          email: { required$,email$ },
          password: { required$ }
        }
      }
    })
    
    const v$= useVuelidate(rules,{form});
    return {
        form,
        v$,
        login,
        authStore
    }
}
};
</script>

<style>

</style>