<template>
<div>
<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style="width:35vh;z-index:999999;">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Open2KEY</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body d-flex flex-column">
      <ul class="navbar-nav" >
        <li class="nav-item fade-in me-auto" @click="authStore.resetStates">
          <router-link :to="{ name: 'Home' }" class="nav-link"  ><svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" widht="24" height="24"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1 6V15H6V11C6 9.89543 6.89543 9 8 9C9.10457 9 10 9.89543 10 11V15H15V6L8 0L1 6Z" fill="#000000"></path> </g></svg> Inicio</router-link>
        </li>
        <li class="nav-item fade-in me-auto" v-if="authStore.user && authStore.user.email_verified_at" @click="authStore.resetStates">
          <router-link :to="{ name: 'Logs' }" class="nav-link " ><svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="#000000"> <path d="M5.314 1.256a.75.75 0 01-.07 1.058L3.889 3.5l1.355 1.186a.75.75 0 11-.988 1.128l-2-1.75a.75.75 0 010-1.128l2-1.75a.75.75 0 011.058.07zM7.186 1.256a.75.75 0 00.07 1.058L8.611 3.5 7.256 4.686a.75.75 0 10.988 1.128l2-1.75a.75.75 0 000-1.128l-2-1.75a.75.75 0 00-1.058.07zM2.75 7.5a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H2.75zM2 11.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H2.75a.75.75 0 01-.75-.75zM2.75 13.5a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z"></path> </g> </g></svg> Ver logs</router-link>

        </li>
        <li class="nav-item fade-in me-auto" v-if="authStore.user  && authStore.user.email_verified_at && authStore.user.rol!=1" @click="authStore.resetStates">
          <router-link :to="{ name: 'Users' }" class="nav-link" ><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="User / Users_Group"> <path id="Vector" d="M17 20C17 18.3431 14.7614 17 12 17C9.23858 17 7 18.3431 7 20M21 17.0004C21 15.7702 19.7659 14.7129 18 14.25M3 17.0004C3 15.7702 4.2341 14.7129 6 14.25M18 10.2361C18.6137 9.68679 19 8.8885 19 8C19 6.34315 17.6569 5 16 5C15.2316 5 14.5308 5.28885 14 5.76389M6 10.2361C5.38625 9.68679 5 8.8885 5 8C5 6.34315 6.34315 5 8 5C8.76835 5 9.46924 5.28885 10 5.76389M12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg> Ver usuarios</router-link>
        

        </li>
        <li class="nav-item fade-in me-auto" v-if="authStore.user  && authStore.user.email_verified_at && authStore.user.rol>=3" @click="authStore.resetStates">
          <router-link :to="{ name: 'Empresas' }" class="nav-link" ><svg fill="#000000" height="24" width="24" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 55 55" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M54.832,52.445l-2-3C52.646,49.167,52.334,49,52,49h-8V16.4c0-0.283-0.12-0.554-0.331-0.743l-16-14.4 c-0.294-0.265-0.716-0.331-1.076-0.17C26.232,1.247,26,1.606,26,2v47h-2V23.474c0-0.306-0.14-0.596-0.38-0.785l-12-9.474 c-0.301-0.236-0.71-0.282-1.056-0.114C10.219,13.268,10,13.617,10,14v35H3c-0.335,0-0.647,0.167-0.832,0.445l-2,3 c-0.205,0.307-0.224,0.701-0.05,1.026C0.292,53.797,0.631,54,1,54h53c0.369,0,0.708-0.203,0.882-0.528 C55.056,53.147,55.037,52.752,54.832,52.445z M28,4.245l14,12.601V49h-2V19h-2v30h-2V16h-2v33h-2V12h-2v37h-2V4.245z M12,16.064 l10,7.894V49h-2V26h-2v23h-2V23h-2v26h-2V16.064z M2.869,52l0.667-1H11h12h4h3h2h2h2h2h2h3h8.465l0.667,1H2.869z"></path> </g> </g></svg> Ver empresas</router-link>
        </li>
      </ul>
      <ul class="navbar-nav me-auto">
        <li class="nav-item" v-if="!authStore.user && authStore.isLoading==false">
          <router-link :to="{ name: 'Login' } " class="nav-link" ><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M2.00098 11.999L16.001 11.999M16.001 11.999L12.501 8.99902M16.001 11.999L12.501 14.999" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path opacity="0.5" d="M9.00195 7C9.01406 4.82497 9.11051 3.64706 9.87889 2.87868C10.7576 2 12.1718 2 15.0002 2L16.0002 2C18.8286 2 20.2429 2 21.1215 2.87868C22.0002 3.75736 22.0002 5.17157 22.0002 8L22.0002 16C22.0002 18.8284 22.0002 20.2426 21.1215 21.1213C20.2429 22 18.8286 22 16.0002 22H15.0002C12.1718 22 10.7576 22 9.87889 21.1213C9.11051 20.3529 9.01406 19.175 9.00195 17" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> </g></svg> Iniciar Sesion</router-link>
        </li>
        <li class="nav-item me-auto" v-if="authStore.user  && !authStore.user.email_verified_at">
          <a href="#" class="nav-link-custom my-auto" @click="notify">Verificar correo</a>

        </li>
      </ul>
        <div class="nav-item mx-auto mt-auto" v-if="authStore.user">
        <hr width="200px" class="my-4 mt-auto">
          <svg class="my-auto" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="lead-sm  ">{{authStore.user.nombre}} {{authStore.user.apellido}}</p>
          <a href="#" class="nav-link-custom my-auto text-danger" @click="logout">Cerrar Sesión</a>
        </div>
  </div>
</div>

<nav class="w-100 navbar navbar-light bg-light">
  <div class="container-fluid w-100">
    <!-- <a class="navbar-brand" href="#">Open2Key</a> -->
    <div>
      <a @click="irInicio();authStore.resetStates" href="#"><img :src="require('@/assets/OPEN2KEYLOGO-01.png')" width="90" height="60" @click="irInicio()"></a>
    </div>
    
    <button class="navbar-toggler me-auto" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="fade-in nav-item ms-auto d-flex align-items-center justify-content-center my-auto me-3" v-if="authStore.user">
          <svg class="my-auto" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="lead-sm  my-auto">{{authStore.user.nombre}} {{authStore.user.apellido}}</p>
        </div>
  </div>
</nav>


</div>
</template>

<script>
import {useAuthStore} from '../stores/auth';
import router from '../router/index';
export default {
  setup(){
    const authStore=useAuthStore();
    
    function logout(){
      authStore.handleLogout();
    }
    function notify(){
      authStore.handleNotify();

    }
    function irInicio(){
      router.push('/')
    }
    return{
      logout,
      authStore,
      notify,
      irInicio
    }
  }
};

</script>

<style>
.router-link-active {
  font-weight: bold;
  color: #007bff; /* Cambia esto al color que prefieras */
  border-bottom: 2px solid #007bff; /* Ejemplo de subrayado */
}
.nav-link-custom {
  display: inline-block;
  padding: 0.5rem ;
  color: #2d3f41;
  text-decoration: none;
  background-color: transparent;
}

.nav-link-custom:hover {
  color: #0056b3;
  text-decoration: underline;
}
</style>