

<script>
import { onMounted} from 'vue'
import {useAuthStore} from '../stores/auth'

export default {
    setup(){
        const authStore = useAuthStore();
        onMounted(async() => {
            await authStore.getUser();
            //console.log(authStore.user);
        });
        return{
            authStore
        }
    },
};

</script>
<template>
<div>


    <div v-if="authStore.loading" class="spinner-border m-5" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
    <div class="mx-5" v-if="authStore.user && !authStore.loading">
        <!-- Se retira  -->
        <!-- <div class="mx-auto" style="max-width:200px; height:auto;">
            <img class="mb-3 fade-in-2  mx-auto img-fluid" :src="require('@/assets/OPEN2KEYLOGO-01.png')" >
        </div> -->
        
        <div class="d-flex w-100 mt-4 gap-5 justify-content-around flex-wrap ">
                <div class="card flex-fill fade-in-1" style="width:18rem;" >
                    <img :src="require('@/assets/estadistica.png')" class="card-img-top mx-auto mt-2 " style="width:5rem;">
                    <div class="card-body">
                        <h4 class="card-title fw-bold">Monitoreo de sistema</h4>
                        <h6 class="card-text">Cada vez que se genera una transacción, firma o autorización, el sistema exige autenticación multifactor. Esto no solo asegura la legitimidad del acceso, sino que también crea registros detallados de auditoría en tiempo real.</h6>
                    </div>
                </div>
                <div class="card flex-fill fade-in-2" style="width: 18rem;">
                    <img :src="require('@/assets/usuarios.png')" class="card-img-top mx-auto mt-2 " style="width:5rem;">
                    <div class="card-body">
                        <h4 class="card-title fw-bold">Gestión de usuarios</h4>
                        <h6 class="card-text">Antes de acceder o utilizar un código seguro, los usuarios deben completar una autenticación multifactor para confirmar su identidad.</h6>
                    </div>
                </div>
                <div class="card flex-fill fade-in-3" style="width: 18rem;">
                    <img :src="require('@/assets/soporte.png')"  class="card-img-top mx-auto mt-2 " style="width:5rem;" >
                    <div class="card-body">
                        <h4 class="card-title fw-bold">Autenticación con Código Multifactor (MFA)</h4>
                        <h6 class="card-text">Para acceder a diferentes servicios o plataformas, el usuario primero introduce su contraseña y luego recibe un código temporal en su dispositivo registrado.  </h6>
                    </div>
                </div>

                
            </div>
        </div>
    <div class="mx-5 mb-5" v-if="!authStore.user && !authStore.loading">
        <!-- <div class="mx-auto" style="max-width:400px; height:auto;">
            <img class="mb-4 fade-in-2  mx-auto img-fluid" :src="require('@/assets/OPEN2KEYLOGO-03.png')" >
        </div> -->
            <div class="d-flex w-100 mt-4 gap-5 justify-content-around flex-wrap">
                <div class="card flex-fill fade-in-1" style="width:18rem;" >
                    <img :src="require('@/assets/estadistica.png')" class="card-img-top mx-auto mt-2 " style="width:5rem;">
                    <div class="card-body">
                        <h4 class="card-title fw-bold">Monitoreo de sistema</h4>
                        <h6 class="card-text">Cada vez que se genera una transacción, firma o autorización, el sistema exige autenticación multifactor. Esto no solo asegura la legitimidad del acceso, sino que también crea registros detallados de auditoría en tiempo real.  </h6>
                    </div>
                </div>
                <div class="card flex-fill fade-in-2" style="width: 18rem;">
                    <img :src="require('@/assets/usuarios.png')" class="card-img-top mx-auto mt-2 " style="width:5rem;">
                    <div class="card-body">
                        <h4 class="card-title fw-bold">Gestión de usuarios</h4>
                        <h6 class="card-text">Antes de acceder o utilizar un código seguro, los usuarios deben completar una autenticación multifactor para confirmar su identidad. </h6>
                    </div>
                </div>
                <div class="card flex-fill fade-in-3" style="width: 18rem;">
                    <img :src="require('@/assets/soporte.png')"  class="card-img-top mx-auto mt-2 " style="width:5rem;" >
                    <div class="card-body">
                        <h4 class="card-title fw-bold">Autenticación con Código Multifactor (MFA)</h4>
                        <h6 class="card-text">Para acceder a diferentes servicios o plataformas, el usuario primero introduce su contraseña y luego recibe un código temporal en su dispositivo registrado. </h6>
                    </div>
                </div>

                
            </div>
    </div>
</div>
</template>
<style>

</style>