<template>
<div>
  <div class="lead mb-2">Restablecer contraseña</div>
  <form @submit.prevent="reset()" novalidate class="d-flex flex-column w-100 justify-content-center align-items-center" >
    <div class="mb-3">
      <input type="password" v-model="this.form.password" placeholder="Nueva contraseña" class="form-control form-control-l"/>
      <small><span v-for="error in v$.form.password.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
    </div>
    <div class="mb-3">
      <input type="password" v-model="form.confirmation" placeholder="Repetir contraseña" class="form-control"/>
      <small><span v-for="error in v$.form.confirmation.$errors" :key="error.$uid" class="text-danger" v-html="error.$message"></span></small>
    </div>
    <div class="mb-3">
      <button
        type="submit"
        class="btn btn-primary
        "
      >
        Restablecer contraseña
      </button>
    </div>
  </form>
  </div>
</template>

<script>
import {ref,computed} from 'vue'
import {useAuthStore} from '../stores/auth'
import {useVuelidate} from '@vuelidate/core'
import {required$} from './validations'
import { useRoute } from 'vue-router'
export default {
    setup(){
        const route=useRoute();
        const form=ref({
            password:'',
            password_confirmation:'',
            email: route.query.email,
            token: route.params.token
        })
        async function reset(){
            await authStore.handleReset(form.value);
        }
        const authStore=useAuthStore()
        const rules= computed(()=>{
            return{
                form:{
                    password:{required$},
                    confirmation:{required$}
                }
            }
        })
        const v$=useVuelidate(rules,{form})
        return{
            form,
            reset,
            v$
        }
    }
}
</script>

<style>

</style>