
import { required, maxLength, minLength, helpers, email,sameAs,numeric} from "@vuelidate/validators";

export const required$ = helpers.withMessage(`Este campo no puede estar vacío<br>`, required);
export const email$ = helpers.withMessage('Ingrese un email válido<br>', email);
export const minLength$ = (min) => helpers.withMessage(
  ({ $params}) => `Este campo debe tener mínimo ${$params.min} digitos.`, minLength(min)
)
export const maxLength$ = (max) => helpers.withMessage(
  ({ $params }) => `Este campo debe tener máximo ${$params.max} dígitos.`,  maxLength(max)
);

export const exactLength$ = (length) => helpers.withMessage(
    `Este campo debe tener exactamente ${length} dígitos.`,
    helpers.withParams({ type: 'exactLength', length }, (value) => value.length === length)
  );

export const sameAs$ = (value) => helpers.withMessage(
  () => `La contraseña no coincide`,  sameAs(value)
);
export const numeric$ = helpers.withMessage('Este campo debe ser numérico',numeric);


