import { toast} from 'vue3-toastify'
function showErrorToast(mensaje){
    toast.error(mensaje,{
      autoClose:3000,
      hideProgressBar:true
    })
  }
function showSuccessToast(mensaje){
    toast.success(mensaje,{
      autoClose:3000,
      hideProgressBar:true
    })
  }

  export {
    showErrorToast,
    showSuccessToast
  }