<template>
  <div class="display-5">Email verificado con éxito!</div>
</template>

<script>
import {useAuthStore} from '../stores/auth'
import {ref,onMounted} from 'vue'
import { useRoute } from 'vue-router'
export default {
    setup(){
        const route=useRoute()
        const data=ref({
            id: route.params.id,
            hash: route.params.hash,
            signature: route.query.signature
        })
        const authStore=useAuthStore();
        
        onMounted(
            authStore.handleVerify(data)
        )
        return{

        }
    }
}
</script>

<style>

</style>