import { createApp,markRaw } from 'vue'
import { createPinia } from 'pinia'
import Vue3Toastify from 'vue3-toastify';
import router from './router';
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap'; 
import './axios'
import App from './App.vue'


const app = createApp(App);
const pinia = createPinia();
pinia.use(({store})=>{
    store.router = markRaw(router);
});
app.use(pinia)
app.use(router)
app.use(Vue3Toastify)
app.mount('#app')

